import $ from 'jquery'

class OffcanvasMenu {
    constructor() {
        this.elements = {
            menu: $('.c-nav-offcanvas'),
            topLevelMenuItem: $('.c-nav-offcanvas > .menu > .menu__item'),
            menuItemWithChildren: $('.c-nav-offcanvas .menu__item--has-children'),
            closeButton: $('.c-page-header__button--menu')
        }

        // Handle clicking a menu item with children
        this.elements.menuItemWithChildren.find('> a').on('click', (e) => this.showSubMenu(e))

        // Reset the menu state when the offcanvas is closed
        this.elements.closeButton.on('click', (e) => this.resetMenuState(e))
    }

    showSubMenu(event) {
        event.preventDefault()
        let parentEl = $(event.target).parent()

        this.elements.menu.addClass('sub-menu-is-active')

        this.elements.menuItemWithChildren.not(parentEl).removeClass('is-active')
        parentEl.toggleClass('is-active')

        let activeMenuItems = this.elements.menuItemWithChildren.filter(function() {
            return $(this).hasClass('is-active')
        })

        if (activeMenuItems.length === 0) {
            this.elements.menu.removeClass('sub-menu-is-active')
        }
    }

    resetMenuState(event) {
        setTimeout(() => {
            this.elements.menu.removeClass('sub-menu-is-active')
            this.elements.menuItemWithChildren.removeClass('is-active')
        }, 500)
    }
}

export default OffcanvasMenu