import PageHeader from './modules/PageHeader'
import PageFooter from './modules/PageFooter'
import OffcanvasMenu from './modules/OffcanvasMenu'
import WPMLMenu from './modules/WPMLMenu'
import RoomContents from './modules/RoomContents'
import ScrollDirection from './modules/ScrollDirection'

new PageHeader()
new PageFooter()
new OffcanvasMenu()
new WPMLMenu()
new ScrollDirection()
new RoomContents()

$(() => {
    // Booking toggle animation helper
    $('.c-booking-options .c-booking-options__list > .c-booking-options__item:not(:first-child)').each(function() {
        let height = this.scrollHeight
        
        this.style.setProperty('--booking-option-height', `${height}px`);
    })
    
    $('[data-module="js-booking-toggle"]').on('click', ({target}) => {
        $('body').toggleClass('book-menu-is-active')
    })

    $('body').on('keydown', function(e) {
        if(e.key == 'Escape') {
            $('body').removeClass('book-menu-is-active')
            $('body').removeClass('book-offcanvas-is-active')
        }
    });

      // $('.js-booking-form').pickadate({
    //     weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    //     showMonthsShort: true
    // });
// FUCK YOU DATE PICKER ( ꒪Д꒪)ノ
    $('.picker__weekday').each( function() {
        this.innerHTML = Array.from(this.title)[0]

    })
})