import $ from 'jquery'

class PageHeader {
    constructor() {
        this.elements = {
            body: $('body'),
            pageHeader: $('.c-page-header')
        }

        if (this.pageHasHero()) {
            this.elements.body.addClass('page-has-hero')
        }
    }

    pageHasHero() {
        let heroEl = this.elements.body.find('.c-hero')

        return (heroEl.length > 0)
    }
}

export default PageHeader