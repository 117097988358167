import $ from 'jquery'

class PageFooter {
    constructor() {
        this.elements = {
            footer: $('.c-page-footer')
        }

        this.calcFooterHeight()

        window.addEventListener('resize', this.calcFooterHeight.bind(this))
    }

    calcFooterHeight() {
        let footerHeight = this.elements.footer.outerHeight()
        document.documentElement.style.setProperty('--page-footer-height', `${footerHeight}px`);
    }
}

export default PageFooter