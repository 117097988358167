import $ from 'jquery'

class RoomContents {
    constructor() {
        this.elements = {
            headings: $('.c-media--room-content .c-media__headings'),
        }

        this.moveHeadings()
    }
    
    moveHeadings() {
        this.elements.headings.each(function(){
            $(this).closest('.c-media--room-content').append(this);
        })
    }
}

export default RoomContents