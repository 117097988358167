import $ from 'jquery'

class WPMLMenu {
    constructor() {
        this.elements = {
            menu: $('.c-nav-wpml'),
            topLevelMenuItem: $('.c-nav-wpml > .menu > .menu__item'),
            menuItemWithChildren: $('.c-nav-wpml .menu__item--has-children'),
            subMenu: $('.c-nav-wpml > .menu .sub-menu'),
        }

        this.subMenuWrap()

        // Handle clicking a menu item with children
        this.elements.menuItemWithChildren.find('> a').on('click', (e) => this.showSubMenu(e))
    }
    
    subMenuWrap() {
        this.elements.subMenu.find('> .menu__item').wrapAll('<div class="sub-menu-wrap"></div>')

    }

    showSubMenu(event) {
        event.preventDefault()
        let parentEl = $(event.target).parent()

        this.elements.menu.toggleClass('sub-menu-is-active')
        this.elements.menuItemWithChildren.toggleClass('is-active')
        this.elements.subMenu.toggleClass('is-active')
        

        // this.elements.menuItemWithChildren.not(parentEl).removeClass('is-active')
        // parentEl.toggleClass('is-active')

        // let activeMenuItems = this.elements.menuItemWithChildren.filter(function() {
        //     return $(this).hasClass('is-active')
        // })

        // if (activeMenuItems.length === 0) {
        //     this.elements.menu.removeClass('sub-menu-is-active')
        // }
    }

    resetMenuState(event) {
        setTimeout(() => {
            this.elements.menu.removeClass('sub-menu-is-active')
            this.elements.menuItemWithChildren.removeClass('is-active')
        }, 500)
    }
}

export default WPMLMenu